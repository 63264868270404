import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import axios from "axios";


import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { plugin, defaultConfig } from "@formkit/vue";
import { createPinia } from "pinia";
import piniaPersist from 'pinia-plugin-persist';
// import '@formkit/themes/genesis/theme.css'

const pinia = createPinia()
pinia.use(piniaPersist)

const app = createApp(App);
app.use(pinia);
// app.use(plugin, defaultConfig);
app.use(plugin, defaultConfig({
    theme: 'genesis' // will load from CDN and inject into document head
  }))
app.use(router);
app.mount("#app");

// WARNING!! disable SSL check when using fake auth server
if (process.env.VUE_APP_OIDC_VERIFY_SSL == 'False' || process.env.VUE_APP_OIDC_VERIFY_SSL == false) {
    process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
    console.warn(`SSL certificates check is disabled.`)
}
axios.defaults.withCredentials = true;
axios.defaults.baseURL = `${process.env.VUE_APP_BACKEND_PATH}/`; // the FastAPI backend

// console.log(process.env)
