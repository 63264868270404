import { defineStore } from "pinia";
import axios from "axios";
import * as d3 from "d3";

import { storeToRefs } from "pinia";
import { useUserStore } from "@/store/useUser";
import router from './../router';
import auth_service from '../user_manager_auth'
// import all commands from commands.js
import {
  AddAnnotationCommand, DeleteAnnotationCommand, UpdateAnnCommand, AddLaneOfTypeCommand,
  AddLaneCommand, UpdateLaneCommand, DeleteLaneCommand, CopyLaneCommand, GenerateFixedAnnsCommand,
  AddSpecificAnnotationCommand
}
  from '../commands';




export const useAnnotationsStore = defineStore("annotations", {
  /* This store holds all state about the currently opened project that need to be shared. In order to use the store in a component,
   simply put ...mapStore(useAnnotationStore) in the "computed" pgeart of a Vue component and "import {useAnnotationsStore} from '@/store/useAnnotations'" to load the store.
   The store is then available in the component by the name "annotationsStore" (it is automa  ally set by pinia to be the StoreID+"Store").
   
   The "state" variable holds all variables of the store. The "actions" property holds all methods like setters.
   */
  state: () => ({
    // Current project id
    project_id: null,

    // Player variables
    currenttime: 0,
    playerPlaying: false,
    timeRange: 0,
    timeRangeScale: 0.5,
    sources: {
      mainSource: null,
      secondarySource: null,
    },
    subtitles: {
      main: {
        kind: "captions",
        label: "Subtitles", // this will be displayed in options menu
        // language: "de",
        srclang: "de",
        src: undefined,
        default: true,
        mode: 'showing'
      },
      secondary: {
        kind: "captions",
        label: "subVideo2",
        // language: "de",
        srclang: "de",
        src: undefined,
        default: true,
        mode: 'showing'
      }
    },
    playerready: false,

    // Annotation variables
    annotations: [],
    new_annotations: [], // the list of new annotations, not yet commited to backend
    deleted_annotations: [], //the list of deleted annotations, not yet commited to backend
    lanesInfo: [],
    new_lanesInfo: [], // the list of new lanes, not yet commited to backend
    deleted_lanesInfo: [], //the list of deleted lanes, not yet commited to backend
    selectedAnnotation: null,
    activeLane: null,
    projSettings: null,

    // Settings variables
    hotkeysOn: true,
    // openAnnotation: null, // Which Annotation (its id) is currently being added and is still open.
    openAnnotations: [], // Which Annotations (their ids) are currently being added and are still open.
    AnnotationLength: 20, // Default length in case of "Fixed", initial and min length in case of "Open".
    AnnotationType: "Fixed", // One of ["Open", "Fixed"].
    // UIViewMode: "Columns", // One of ["Columns", "Tabular"]
    UIViewMode: "Columns", // One of ["Columns", "Tabular"]
    openAllSelects: false, // if true, will display all the options from select
    DefaultTextAnnotation: "Neue Annotation",
    DefaultTextLane: "Neue Spur",
    DefaultStructType: "simple",
    DefaultStructProperties: {},

    newLanesBtnVisible: true,
    structAnnotTypesSettings: [],
    annotTypesAll: [],
    currentLaneId: null,

    defaultAnnotSettings: {
      "annotation_types":
        [
          {
            "type_name": "Simpel",
            "fields": [
              {
                "name": "Text",
                "input_type": "textarea"
              }
            ]
          }
        ]
    },
    commandStack: [],
    redoStack: [],
    lanesFilters: [], // example of filter [{"title": [ ... ]}, {"owner":[....]}]

  }),
  getters: {
    canUndo() {
      return this.commandStack.length > 0;
    },
    canRedo() {
      return this.redoStack.length > 0;
    },

  },
  actions: {
    // ----------- Backend communication ------------------------
    fetchAnnotations(id) {
      // Run this method in the mounted hook of the AnnoationsApp view
      axios
        .get("/annotations/" + id, this.getConfigAuth())
        .then((response) => {
          this.annotations = response.data.annotations;
          this.lanesInfo = response.data.lanesInfo;
        })
        .catch((error) => {
          router.push("/"); // --> Check if this works, might need to import router from main.js, and then router.push
        });

      this.project_id = id;
      this.newLanesBtnVisible = true;
    },
    async fetchOneAnn(ann_id) {
      try {
        const response = await axios.get("/annotations/get_one/" + ann_id, this.getConfigAuth());
        // console.log("-->resp:", response)
        // console.log("-->resp data:", response.data)
        return response.data;
      }
      catch (error) {
        console.error(error);
      }
    },
    async fetchOneLane(lane_id) {
      try {
        const response = await axios.get("/lanes/get_one/" + lane_id, this.getConfigAuth());
        return response.data;
      }
      catch (error) {
        console.error(error);
      }
    },
    async fetchAnnsOnLane(lane_id) {
      try {
        const response = await axios.get("/annotations/get_on_lane/" + lane_id, this.getConfigAuth());
        return response.data;
      }
      catch (error) {
        console.error(error);
      }
    },
    updateAnnsOnLane(laneId, annsData) {
      // update all annots on lane with id "laneId" with the annsData
      axios.put("/annotations/update_on_lane/" + laneId,
        annsData,
        this.getConfigAuth()
      );
    },
    updateAnnot(ann_id) {
      // update annotation with ann_id to the backend
      let annToUpdate = this.getAnnotWithId(ann_id);
      // console.log("--- ann to update:", annToUpdate)

      axios.put("/annotations/update_one/" + this.project_id,
        annToUpdate,
        this.getConfigAuth()
      );
    },

    updateAnnotWithData(ann_data) {
      axios.put("/annotations/update_one/" + this.project_id,
        ann_data,
        this.getConfigAuth()
      );
    },
    createAnnotation(ann_id) {
      // create annotation with ann_id to the backend
      let annToCreate = this.getAnnotWithId(ann_id);
      console.log("--- ann to create:", annToCreate)

      axios.put("/annotations/create_one/" + this.project_id,
        annToCreate, this.getConfigAuth()
      );
    },
    createSpecificAnnotation(ann_data) {
      axios.put("/annotations/create_one/" + this.project_id,
        ann_data, this.getConfigAuth()
      );
    },
    pushDeleteAnnotation(ann_id) {
      axios.put("/annotations/delete/" + this.project_id + "/" + ann_id, {},
        this.getConfigAuth());
    },


    getConfigAuth() {
      // get the auth configuration for the requests to backend
      // access user store to get token
      const user = useUserStore();
      const AUTH_token = user.AUTH_token;

      // console.log("--AUTH TOKEN:", AUTH_token)
      // console.log("user:", user)


      // Get video streaming links for project
      let config = {
        headers: {
          'auth-token': `${user.AUTH_token}`
        },
      };
      return config;
    },
    getLoggedInUser() {
      const user = useUserStore();
      return user.user_profile;
    },
    pushEditedLaneTitle(laneId, newTitle) {
      axios.put("/lanes/update_title/" + this.project_id, {
        lane_id: laneId,
        new_title: newTitle
      }, this.getConfigAuth())
    },
    updateOneLane(newData) {
      axios.put("/lanes/update_one/" + this.project_id, newData, this.getConfigAuth())
    },
    fetchProjectSettings(proj_id) {
      axios
        .get("/proj_settings/" + proj_id, this.getConfigAuth())
        .then((response) => {
          this.projSettings = response.data;
          // console.log("fetched project settings:", this.projSettings);

          this.setAnnotationLength(this.projSettings.annotationLength);
          this.setAnnotationType(this.projSettings.annotationLenType);

          this.structAnnotTypesSettings = response.data.annotationConfig;
          this.annotTypesAll = this.extractAnnotTypesFromSettings(response.data.annotationConfig);
        })
        .catch((error) => {

          console.error("error fetching project settings:", error)
          // router.push("/"); // --> Check if this works, might need to import router from main.js, and then router.push
          router.back(); // go back to the previous page
        });

      this.project_id = proj_id;
    },
    updateProjSettings(annLengthNew, annotationTypeNew, projTitleNew, projDescNew, lanesToCopy) {

      const body = {
        title: projTitleNew,
        description: projDescNew,
        annotationLength: annLengthNew,
        annotationLenType: annotationTypeNew,
        videos: this.projSettings.videos,
        annotationConfig: JSON.stringify(this.projSettings.annotationConfig),
        editableBy: this.projSettings.editableBy,
        owner: this.projSettings.owner,
        visibleTo: this.projSettings.visibleTo,
        lanesToCopy: lanesToCopy
      }

      axios.put("/project_settings/update/" + this.project_id, body, this.getConfigAuth())
        .then((response) => {
          // console.log("status:", response.status)
          // console.log("response:", response)
          this.setAnnotationLength(annLengthNew);
          this.setAnnotationType(annotationTypeNew);

          // fetch annotations again to retrieve copied lanes for the added users, or deleted lanes for deleted users:

          this.fetchAnnotations(this.project_id);
        })
        .catch((error) => {
          alert("error updating project settings. Try again");
          console.error("error updating project settings:", error)
        });
    },
    fetchSubtitles(video_id, type = "main") {
      // fetch subtitles for a video, type can be "main" or "secondary"
      let config = this.getConfigAuth()

      axios
        .get("/videos/subtitles/" + video_id, config)
        .then((res) => {
          // console.log("!!!!!!!!!!!!!!! fetch subtitles:", res.data)
          if (res.data.status == "error") {
            console.warn(res.data.message)
            if (type === "main") {
              this.subtitles.main["src"] = "";
            } else {
              this.subtitles.secondary["src"] = "";
            }
          } else {
            console.log("fetch subtitles for type:", type)
            if (type == "main") {
              this.subtitles.main["src"] = res.data.path
            } else {
              this.subtitles.secondary["src"] = res.data.path
            }
            console.log(">>>>>>>>this.subs:", this.subtitles)
          }
        }).catch((error) => {
          console.error(error);
        });
    },
    fetchVideos(id) {
      console.log("fetching videos");
      // Run this method in the mounted hook of the AnnoationsApp view
      // access user store to get token
      const user = useUserStore();

      const { HILDE_token } = storeToRefs(user);

      // Get video streaming links for project
      let config = this.getConfigAuth()
      config["params"] = {
        project_id: id,
      }
      if (HILDE_token.value) {
        config.headers["hilde-token"] = HILDE_token.value;
      }

      console.log(`fetching videos for project ${id}`);
      axios
        .get("/video_projects", config)
        .then((res) => {
          // set sources for player
          console.log(`fetched results are`);
          console.log(res.data);
          const sources = {
            mainSource: res.data?.[id]?.videos?.[0]?.video_url ?? null,
            secondarySource: res.data?.[id]?.videos?.[1]?.video_url ?? null
          }

          // fetch subtitles for videos:
          this.fetchSubtitles(res.data?.[id]?.videos?.[0]?.id ?? undefined, "main")
          this.fetchSubtitles(res.data?.[id]?.videos?.[1]?.id ?? undefined, "secondary")
          console.log("!!!!!! videos:", res.data?.[id]?.videos)
          console.log(`setting sources to`);
          console.log(sources)
          this.sources = sources
        })
        .catch((error) => {
          console.error(error);
        });
      this.project_id = id;
    },
    extractAnnotTypesFromSettings(settings_json) {
      var annotSettings = settings_json["annotation_types"];
      var annotTypesAll = [];
      annotSettings.forEach((annType) => {
        annotTypesAll.push(annType["type_name"]);
      })

      return annotTypesAll;
    },
    // ----------- Setter methods --------------------
    setCurrenttime(time) {
      // console.log("setting current time to", time);
      this.currenttime = time;

      // When there is an open annotation currently, set its end time to the current time
      // if (this.openAnnotation != null) {
      if (this.openAnnotations.length > 0) {

        // open annotation start
        const timestart =
          this.getAnnotWithId(this.openAnnotations[0]).timestart;
        // start time of the next annotation on the same lane
        const laneId =
          this.getAnnotWithId(this.openAnnotations[0]).laneId;

        const later_annotation_starts = this.annotations
          .filter(
            (obj) => obj.laneId === laneId && obj.timestart > timestart
          )
          .map((a) => a.timestart)
        // if list is not empty take the next starting time otherwise infinity
        const next_annotation_start = later_annotation_starts.length > 0 ? Math.min(...later_annotation_starts) : Infinity;

        // new end time of the annotation is max(timestart+1,min(time, next_annotation_start-1))
        const new_end = Math.max(timestart, Math.min(time, next_annotation_start));

        this.openAnnotations.forEach((openAnnotation) => {
          this.setAnnotationEnd(
            openAnnotation,
            new_end
          );
        })
      }
    },
    setTimerange(range) {
      this.timeRange = range;
    },
    pausePlayer() {
      // Action to pause the player through the annotation Component. The videoplayer component listens to this.
      // pretty messy
    },
    continuePlayer() {
      // Action to continue the player through the annotation Component. The videoplayer component listens to this.
      // pretty messy
      // both are only used in the annotation component when dragging the bar indicating the current player time because the player needs to be paused else it will grab the current time from the player and constantly update the bar while the user trys to move it to some other location. Only after the action is completed should the player continue to play.
    },
    setSkiptime(time) {
      // Action to skip to a certain timepoint, used by the Annotation App when dragging the triangle handle. The videoplayer component listens to this.
      return;
    },
    setPlayerplaying(playing) {
      this.playerPlaying = playing;
    },
    setPlayerready(bool) {
      this.playerready = bool;
    },
    setHotkeysOn(bool) {
      this.hotkeysOn = bool;
    },
    focusAnnotation(ann_id) {
      console.log("focus annotation");
      this.selectedAnnotation = ann_id;
      return;
    },
    setAnnotationEnd(ann_id, end) {
      // set end but don't commit to backend:
      // console.log("setting annotation" + ann_id + "end to" + end);
      this.getAnnotWithId(ann_id).timeend = end;
    },
    commitAnnotationEnd(ann_id, end) {
      this.setAnnotationEnd(ann_id, end);
      let newData = this.getAnnotWithId(ann_id);
      this.executeCommand('UpdateAnnCommand', [ann_id, newData]);
      this.removeOpenAnn(ann_id)
    },
    setAnnotationStart(ann_id, start) {
      // set start but don't commit to backend:
      this.getAnnotWithId(ann_id).timestart = start;
    },
    commitAnnotationStart(ann_id, start) {
      this.setAnnotationStart(ann_id, start);
      let newData = this.getAnnotWithId(ann_id);
      this.executeCommand('UpdateAnnCommand', [ann_id, newData]);
    },
    setAnnotationText(ann_id, text) {
      this.getAnnotWithId(ann_id).text = text;
      // this.updateAnnot(ann_id);
      this.executeCommand('UpdateAnnCommand', [ann_id, this.getAnnotWithId(ann_id)]);
    },
    setSelectedAnnotation(ann_id) {
      this.selectedAnnotation = ann_id;
      // make the lane of the selected annotation the active lane:
      if (ann_id != null) {
        this.activeLane = this.getAnnotWithId(ann_id).laneId;
      }
    },
    setActiveLane(laneId) {
      this.activeLane = laneId;
    },
    resetAnnotation(annotation_id, annotation_type) {
      // Reset an annotation to its default values given the new annotation type
      this.getAnnotWithId(annotation_id).structtype = annotation_type;
      this.getAnnotWithId(annotation_id).struct_properties = {};
    },
    setDefaultAnnStructTypes(annotations) {
      // Tim: seems to be doing nothing?
      console.log("set default ann struct types");
      // set by default all annotation struct types to 'simple'
      // TODO: check this code if working
      annotations.forEach(function (annotation) {
        if (!Object.prototype.hasOwnProperty.call(annotation, 'structtype')) {
          console.log("hi")
          annotation.structtype = this.DefaultStructType
        }
      });
      return annotations;
    },
    generateObjectId(m = Math, d = Date, h = 16, s = s => m.floor(s).toString(h)) {
      // generates valid Mongo ObjectId
      return s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h))
    },
    addAnnotation(lane_id) {
      // console.log('Calling addAnnotation: lane_id:', lane_id);
      /* Add a new annotation to an existing lane. The parameter "lane_id" is the lane id in which the annotation shall be added.
      Process:
      [old](1) Check if there is an open annotation currently. If so, close it now and set its end time to the current time.
      [new](1) Check if there is an open anotation on the SAME LANE. If so, close it now and set its end time to the current time. 
      (2) Check if there is already an annotation in this lane at the current time.
      (3) If not, calculate the time to the next annotation in this lane. If the next annotation is too close, return and do not create an annotation.
      (4) Do not create an annotation if the current time is too close to the time range end.
      (5) Create the new annotation object and add it to the annotation array of the store. If the annotation type is "Open", create the new annotation as an open one.
       */
      this.setActiveLane(lane_id);
      const curTime = this.currenttime;
      // console.log("curTime", curTime);

      // [new - multiple open annotatios] (1)
      // get the open annotations on the same lane

      const sameLaneOpenAnots = this.openAnnotations.filter(
        (ann_id) =>
          this.getAnnotWithId(ann_id).laneId == lane_id)

      // console.log("sameLaneOpenAnots", sameLaneOpenAnots)

      // close all the open annotations on the same lane:
      sameLaneOpenAnots.forEach((ann_id) => {
        let ann = this.getAnnotWithId(ann_id);
        let maxTime = Math.min(...this.annotations.filter((obj) => obj.laneId == ann.laneId && obj.timestart > ann.timestart).map((a) => a.timestart))
        let newEnd = Math.min(curTime, maxTime)
        this.setAnnotationEnd(ann_id, newEnd);
        this.closeAnnotation(ann_id);
      });

      // (2)
      const sameTimeslot = this.annotations.filter(
        (obj) =>
          obj.laneId == lane_id &&
          curTime >= obj.timestart &&
          curTime < obj.timeend
      );

      // (3)
      if (!sameTimeslot.length) {
        let timeToNextAnnotation =
          Math.min.apply(
            Math,
            this.annotations
              .filter(
                (obj) => obj.laneId == lane_id && obj.timestart > curTime
              )
              .map((a) => a.timestart)
          ) - curTime;
        if (timeToNextAnnotation < 0.01) {
          return false; // do not create an annotation if the next annotation is too close
        }

        // (4)
        if (curTime + 1 > this.timeRange) {
          return false;
        }

        let laneOfAnn = this.getLaneWithId(lane_id);

        // (5)
        let newAnn = {
          _id: this.generateObjectId(),
          timestart: curTime,
          // CHANGED
          timeend: this.AnnotationType == "Open" ? Math.min(curTime, this.timeRange) : Math.min(curTime + Math.min(this.AnnotationLength, timeToNextAnnotation), this.timeRange),
          // timeend: this.AnnotationType == "Open" ? Math.min(curTime + 0.1, this.timeRange) : Math.min(curTime + Math.min(this.AnnotationLength, timeToNextAnnotation - 0.001), this.timeRange),
          text: this.DefaultTextAnnotation,
          laneId: laneOfAnn._id,
          owner: this.getLoggedInUser().email,
          editableBy: [this.getLoggedInUser().email],
          visibleTo: [this.getLoggedInUser().email],
          proj_id: this.project_id,
          // structtype: this.DefaultStructType,
          structtype: this.getLaneWithId(lane_id).annType,
          struct_properties: {}
        };

        // console.log("--- new ann:", newAnn);


        this.annotations.push(newAnn);
        // this.createAnnotation(newAnn._id);
        this.executeCommand('AddSpecificAnnotationCommand', [newAnn._id, newAnn]);

        if (this.AnnotationType == "Open") {
          this.setOpenAnnotation(this.annotations[this.annotations.length - 1]._id);
        }
        // return true; // return true if an annotation was added
        return this.annotations[this.annotations.length - 1]._id; // return the id of the new annotation
      }
    },

    generateFixedAnnotsOnLane(fixedAnnSize, laneId) {
      // first delete all annotations on the lane:
      axios.put("/lane/delete_all_anns/" + this.project_id + "/" + laneId, {},
        this.getConfigAuth());

      // generate fixed size annotations on lane:
      axios.put("/lane/generate_fixed_anns/" + this.project_id + "/" + laneId, {
        "ann_size": fixedAnnSize,
        "time_range": this.timeRange
      }, this.getConfigAuth())
        .then(() => {
          this.fetchAnnotations(this.project_id);
        });

    },
    laneOfLoggedinUser(laneId) {
      if (laneId === null) {
        return false;
      }
      let lane = this.getLaneWithId(laneId);
      if (lane === undefined) {
        return false;
      }
      return this.getLoggedInUser().email == lane.owner;
    },
    checkSameTimeSlotAnnot(laneId) {
      let curTime = this.currenttime;
      const sameTimeslot = this.annotations.filter(
        (obj) =>
          obj.laneId === laneId &&
          curTime >= obj.timestart &&
          curTime <= obj.timeend
      );
      return sameTimeslot.length > 0;
    },
    setOpenAnnotation(id) {
      if (id !== null) {
        this.openAnnotations.push(id)
      }
      // if (id === null) {
      //   // When an open annotation is closed, push the changes to the API
      //   this.updateAnnot(id);
      // }
    },
    closeAnnotation(annToUpdate) {
      // Close currently open annotation.
      // let annToUpdate = this.openAnnotation;
      // if (annToUpdate) {
      //   this.updateAnnot(annToUpdate);
      // }

      this.closeOpenAnnotation(annToUpdate);
      // this.setOpenAnnotation(null);
    },
    closeOpenAnnotation(ann_id) {
      this.openAnnotations = this.openAnnotations.filter(function (el) { return el != ann_id; }); // new code - multiple annotations with array
      // this.updateAnnot(ann_id)
      this.executeCommand('UpdateAnnCommand', [ann_id, this.getAnnotWithId(ann_id)]);
    },
    closeOpenAnnOnLane(lane_id) {
      this.openAnnotations.forEach(ann_id => {
        let ann = this.getAnnotWithId(ann_id)
        // console.log("open ann on lane:", ann, "liid:", lane_id)
        if (ann.laneId == lane_id) {
          // this.updateAnnot(ann_id)
          this.executeCommand('UpdateAnnCommand', [ann_id, this.getAnnotWithId(ann_id)]);

          this.removeOpenAnn(ann_id)
        }
      })
    },
    removeOpenAnn(ann_id) {
      // remove the annotation with id from the array
      this.openAnnotations = this.openAnnotations.filter(function (el) { return el != ann_id; });
    },
    closeAllOpenAnns() {
      // close all open annotations on all the lanes
      this.openAnnotations.forEach(ann_id => {
        // this.updateAnnot(ann_id)
        this.executeCommand('UpdateAnnCommand', [ann_id, this.getAnnotWithId(ann_id)]);

      })
      this.openAnnotations = []
    },

    deleteAnnotation(ann_id) {
      // console.log("deleting an with id;", ann_id)
      // delete the annot with "_id" id from the array
      this.annotations = this.annotations.filter(function (el) { return el._id != ann_id; });
      this.pushDeleteAnnotation(ann_id);
    },
    stringifyAnnotation(annot) {
      var result = "";
      for (const [name, value] of Object.entries(annot.struct_properties)) {
        if (result != "") {
          result += ", \n";
        }
        result += name + ":" + value;
      }
      if (result == "") {
        return "no info";
      }
      return result;
    },
    // Transform a type of annotation from json to FormKitSchema
    // ref: https://formkit.com/advanced/schema
    structAnnotTypeToFormkitSchema(annType, annot) {
      var formKitResult = [];

      annType["fields"].forEach((field) => {
        let field_component = { $cmp: "FormKit", props: {} };
        const field_types_to_formkit_mapping = {
          text: "text",
          input: "text",
          simple_select: "select",
          multiple_select: "select",
          textarea: "textarea",
          number: "number",
        };

        const field_name = field.name;
        field_component.props["name"] = field_name;
        field_component.props["id"] = field_name;
        field_component.props["label"] = field_name;
        field_component.props["type"] =
          field_types_to_formkit_mapping[field.input_type];

        field_component.props["value"] =
          annot?.struct_properties[field_name] ?? "";

        if (field.input_type == "multiple_select") {
          field_component.props["multiple"] = true;
        }

        if (field_component.props["type"] == "select") {
          field_component.props["options"] = field.options;
        }

        formKitResult.push(field_component);
      });
      return formKitResult;
    },
    addLane(title) {
      this.addLaneOfType(title, "Simpel");
    },
    addSpecificLane(new_lane) {
      this.lanesInfo.push(new_lane);

      axios.put("/lanes/add_new/" + this.project_id, {
        new_lane: new_lane
      }, this.getConfigAuth());

      return new_lane._id;
    },
    addLaneOfType(title, ann_type) {
      let new_lane = {
        "_id": this.generateObjectId(), "title": title, "annType": ann_type,
        "owner": this.getLoggedInUser().email,
        "editableBy": [this.getLoggedInUser().email],
        "visibleTo": [this.getLoggedInUser().email],
        "proj_id": this.project_id,
        "color": this.generateCssColor()
      };
      this.lanesInfo.push(new_lane);

      axios.put("/lanes/add_new/" + this.project_id, {
        new_lane: new_lane
      }, this.getConfigAuth());

      return new_lane._id;
    },

    async copyLane(laneId) {
      try {
        const response = await axios.put("/copy_lane/" + this.project_id + "/" + laneId, {}, this.getConfigAuth());
        // console.log("-->resp:", response)
        // console.log("-->resp data:", response.data)
        this.fetchAnnotations(this.project_id);
        let createdLaneId = response.data;
        return createdLaneId;
      }
      catch (error) {
        console.error(error);
      }
    },

    setLaneTitle(laneId, title) {
      this.getLaneWithId(laneId).title = title;
    },
    deleteLane(laneId) {
      /*
        In order to delete a Lane, first all annotations of that lane are deleted. 
        Finally, the lane is deleted from the lanesInfo array variable and the currently active lane is deselected. 
        The changes are then pushed to the backend.
      */

      // Filter out all annotations except ones on the deleted lane
      this.annotations = this.annotations.filter(function (obj) {
        return obj.laneId !== laneId;
      });

      this.lanesInfo = this.lanesInfo.filter(function (el) { return el._id != laneId; });
      this.activeLane = null; // by default unselect any lane

      axios.put("/lanes/delete/" + this.project_id + "/" + laneId, {}, this.getConfigAuth());
    },

    deleteAnnsOnLane(laneId) {
      axios.put("/lane/delete_all_anns/" + this.project_id + "/" + laneId, {},
        this.getConfigAuth());
    },
    getAnnTypeByBool(annTypeStr) {
      return (annTypeStr == true ? 'Open' : 'Fixed')
    },
    // ----------- Setter methods for the Settings --------------------
    setAnnotationType(type) {
      let allowedTypes = ["Open", "Fixed"];
      if (allowedTypes.includes(type)) {
        this.AnnotationType = type;
      } else {
        // TODO throw error instead?
        return 'Incompatible type. Needs to be one of ["Fixed","Open"].';
      }
    },
    setAnnotationLength(length) {
      this.AnnotationLength = length;
    },
    // ----------- Getter methods --------------------
    getRandomInt(min, max) {
      // get a random integer in range min, max
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
    },
    generateCssColor() {
      let rand_R = this.getRandomInt(100, 255);
      let rand_G = this.getRandomInt(100, 255);
      let rand_B = this.getRandomInt(100, 255);

      const rgbToHex = (r, g, b) => '#' + [r, g, b].map(x => {
        const hex = x.toString(16)
        return hex.length === 1 ? '0' + hex : hex
      }).join('')

      return rgbToHex(rand_R, rand_G, rand_B)
      // ["rgb(", rand_R, ",", rand_G, ",", rand_B, ")"].join("");

      // for lighter, more pale colors:
      // return "hsl(" + 360 * Math.random() + ',' +
      //   (25 + 70 * Math.random()) + '%,' +
      //   (85 + 10 * Math.random()) + '%)'
    },
    getAnnotWithId(id) {
      return this.annotations.find(
        (ann) => ann._id == id
      )
    },
    getLaneWithId(lane_id) {
      return this.lanesInfo.find(
        (lane) => lane._id === lane_id
      )
    },
    getLaneWithIdFromArr(all_lanes, lane_id) {
      return all_lanes.find(
        (lane) => lane._id === lane_id
      )
    },
    sortAnnByStart() {
      // sort the annotations by the start time
      this.annotations.sort((a, b) => (a.timestart > b.timestart) ? 1 : -1)
    },
    sortLanesByOwner() {
      // sort the lanes by the owner
      this.lanesInfo.sort((a, b) => (a.owner > b.owner) ? 1 : -1)
    },
    getNextAnnOnLane(ann_id, lane_id) {
      this.sortAnnByStart();

      let current_annot = this.getAnnotWithId(ann_id);
      let res = this.annotations.filter(
        (ann) => ann.timestart > current_annot.timestart && ann.laneId == lane_id
      );
      if (res.length > 0) {
        return res[0];
      }
      return null;
    },
    getPrevAnnOnLane(ann_id, lane_id) {
      this.sortAnnByStart();

      let current_annot = this.getAnnotWithId(ann_id);
      let res = this.annotations.filter(
        (ann) => ann.laneId == lane_id && ann.timeend < current_annot.timeend
      );
      if (res.length > 0) {
        return res[res.length - 1];
      }
      return null;
    },
    getFirstAnnotOnLane(lane_id) {
      this.sortAnnByStart();
      return this.annotations.find(
        (ann) => ann.laneId == lane_id
      )
    },
    getLastAnnotOnLane(lane_id) {
      this.sortAnnByStart();
      return this.annotations.findLast(
        (ann) => ann.laneId == lane_id
      )
    },
    getAnnotsOnLane(lane_id) {
      let res = this.annotations.filter(
        (ann) => ann.laneId == lane_id
      )
      return res;
    },
    getAnnotsOnLaneForUser(lane_id, user) {
      let res = this.annotations.filter(
        (ann) => (ann.laneId == lane_id && ann.owner == user)
      )
      return res;
    },
    getLanesOfUser(user) {
      let res = this.lanesInfo.filter(
        (lane) => (lane.owner == user)
      )
      return res;
    },
    getNextLane(all_lanes, lane_id) {
      // get the next lane from the array of lane objects all_lanes
      let current_lane = this.getLaneWithIdFromArr(all_lanes, lane_id);
      let lane_idx = all_lanes.indexOf(current_lane);
      // return next lane, or the zero lane if last
      if (lane_idx < all_lanes.length - 1) {
        return all_lanes[lane_idx + 1];
      }
      return all_lanes[0];
    },
    getPrevLane(all_lanes, lane_id) {
      // get the previous lane from the array of lane objects all_lanes

      let current_lane = this.getLaneWithIdFromArr(all_lanes, lane_id);
      let lane_idx = all_lanes.indexOf(current_lane);
      // return previous lane, or the last lane if starting from zero index lane

      if (lane_idx > 0) {
        return all_lanes[lane_idx - 1];
      }
      return all_lanes[all_lanes.length - 1];
    },
    getNextNonEmptyLane(all_lanes, lane_id) {
      // get the next lane that has at least 1 annot from array of lanes all_lanes
      let next_lane = this.getNextLane(all_lanes, lane_id);

      while ((this.getFirstAnnotOnLane(next_lane._id) === undefined) && lane_id != next_lane._id) {
        next_lane = this.getNextLane(all_lanes, next_lane._id);
      }
      return next_lane;
    },
    getPrevNonEmptyLane(all_lanes, lane_id) {
      // get the previous lane that has at least 1 annot
      let prev_lane = this.getPrevLane(all_lanes);

      while ((this.getFirstAnnotOnLane(prev_lane._id) === undefined) && lane_id != prev_lane._id) {
        prev_lane = this.getPrevLane(all_lanes, prev_lane._id);
      }
      return prev_lane;
    },
    userCanEditProj() {
      return this.projSettings?.editableBy.includes(this.getLoggedInUser().email);
    },
    userCanEditAnn(ann) {
      // check if user is the owner of annotation & has permissions to edit
      if (ann.owner == this.getLoggedInUser().email) {
        return true;
      }
      return false;
    },
    userCanEditLane(lane) {
      // check if user is the owner of lane & has permissions to edit
      if (lane == undefined) {
        return false;
      }
      if (lane.owner == this.getLoggedInUser().email) {
        return true;
      }
      return false;
    },
    // add an additional filter to the existing filters:
    addFilter(filter) {
      // adds a new filter for a property, without changing the old filters
      let new_filter_key = filter.property;

      let found_property_filter = this.lanesFilters.find(
        (x) => x.property == new_filter_key
      );

      if (found_property_filter === undefined) {
        this.lanesFilters.push(filter);
      } else {

        // add the new values to the old filter properties
        found_property_filter.values = [
          ...new Set([...found_property_filter.values, ...filter.values]),
        ];

        // delete the old filter for the same property:
        this.lanesFilters = this.lanesFilters.filter(
          (x) => x.property != new_filter_key
        );
        // add the new filter:

        this.lanesFilters.push(found_property_filter);
      }
    },
    // set a new filter, overwrite if property already exists
    setFilter(filter) {
      // adds a new filter for a property, without changing the old filters
      let new_filter_key = filter.property;

      let found_property_filter = this.lanesFilters.find(
        (x) => x.property == new_filter_key
      );

      if (found_property_filter === undefined) {
        this.lanesFilters.push(filter);
      } else {
        // delete the old filter for the same property:
        this.lanesFilters = this.lanesFilters.filter(
          (x) => x.property != new_filter_key
        );
        // add the new filter:
        this.lanesFilters.push(filter);

      }
    },
    resetFilters() {
      this.lanesFilters = [
        {
          property: "owner",
          values: [this.getLoggedInUser().email],
        },
      ];
    },
    async undoLastAction() {
      if (this.commandStack.length > 0) {
        const lastCommand = this.commandStack.pop();
        let log_command = await lastCommand.undo();
        this.fetchAnnotations(this.project_id);

        // return "last action undone:";
        return log_command;
      } else {
        return "no action to undo";
      }
    },
    // Redo the last undone command and push it back to the commandStack
    async redoLastAction() {
      if (this.redoStack.length > 0) {

        const lastRedoCommand = this.redoStack.pop();
        if (lastRedoCommand) {
          let log_command = await lastRedoCommand.redo();
          this.commandStack.push(lastRedoCommand);
          this.fetchAnnotations(this.project_id);

          return log_command;
        }
        else {
          // TODO: check this
          return "no action to redo";

          // return "error. no command to redo";
        }

      } else {
        return "no action to redo";
      }
    },
    executeCommand(command_name, command_args) {
      const commands =
      {
        "AddAnnotationCommand": AddAnnotationCommand,
        "DeleteAnnotationCommand": DeleteAnnotationCommand,
        "UpdateAnnCommand": UpdateAnnCommand,
        "AddLaneCommand": AddLaneCommand,
        "AddLaneOfTypeCommand": AddLaneOfTypeCommand,
        "DeleteLaneCommand": DeleteLaneCommand,
        "UpdateLaneCommand": UpdateLaneCommand,
        "CopyLaneCommand": CopyLaneCommand,
        "GenerateFixedAnnsCommand": GenerateFixedAnnsCommand,
        "AddSpecificAnnotationCommand": AddSpecificAnnotationCommand

      }

      const command = new commands[command_name](...command_args);
      return command.execute();

      // code equivallent to an example:
      // const command = new CopyLaneCommand(this.annotationsStore.currentLaneId);
      // command.execute();
    }

  },
});
