// commands.js
import { useAnnotationsStore } from "@/store/useAnnotations";

// Command Interface
class Command {
  maxStackSize = 50;
  execute() { }
  undo() { }
  redo() { }
  addToCommandStack() {
    this.store.commandStack.push(this);
    // Limit the commandStack to maxStackSize
    if (this.store.commandStack.length > this.maxStackSize) {
      this.store.commandStack.shift(); // Remove the oldest command from the bottom
    }
  }

  addToRedoStack() {
    this.store.redoStack.push(this);
    // Limit the redoStack to maxStackSize
    if (this.store.redoStack.length > this.maxStackSize) {
      this.store.redoStack.shift(); // Remove the oldest command from the bottom
    }
  }
}

class AddAnnotationCommand extends Command {
  constructor(lane_id) {
    super()
    // this.store = store;
    this.store = useAnnotationsStore();
    this.lane_id = lane_id;
    this.addedAnnotationId = null;
  }

  execute() {
    this.addedAnnotationId = this.store.addAnnotation(this.lane_id);
    if (this.addedAnnotationId === undefined) {
      console.log("Annotation could not be added");
      return false;
    } else {
      console.log("execute:add anot", this.addedAnnotationId)
      this.addToCommandStack(); // Save the command to the commandStack
      // Clear the redoStack whenever a new command is executed
      this.store.redoStack = [];
      this.annData = this.store.getAnnotWithId(this.addedAnnotationId);
      return this.addedAnnotationId;
    }
  }
  undo() {
    this.store.deleteAnnotation(this.addedAnnotationId);
    this.addToRedoStack(); // Save the command to the redoStack
    return "UNDO  " + this.constructor.name + " <" + this.addedAnnotationId + ">";
  }
  redo() {
    this.store.createSpecificAnnotation(this.annData);

    // this.addedAnnotationId = this.store.addAnnotation(this.lane_id);
    this.addToCommandStack(); // Save the command to the commandStack
    // this.execute(); // Redo simply means executing the command again
    return "REDO " + this.constructor.name + " <" + this.addedAnnotationId + ">";
  }

}


class AddSpecificAnnotationCommand extends Command {
  constructor(annotationId, annotData) {
    super();
    this.store = useAnnotationsStore();
    this.annotationId = annotationId;
    this.annotData = annotData;
  }

  execute() {
    this.store.createSpecificAnnotation(this.annotData);
    this.addToCommandStack();
    this.store.redoStack = [];
  }

  undo() {
    this.store.deleteAnnotation(this.annotationId);
    this.addToRedoStack();
    return "UNDO " + this.constructor.name + " <" + this.annotationId + ">";
  }

  redo() {
    this.store.createSpecificAnnotation(this.annotData);
    this.addToCommandStack();
    return "REDO " + this.constructor.name + " <" + this.addedAnnotationId + ">";
  }
}


// DeleteAnnotationCommand
class DeleteAnnotationCommand extends Command {
  constructor(annotationId) {
    super();
    // this.store = store;
    this.store = useAnnotationsStore();
    this.annotationId = annotationId;
    this.deletedAnnotation = null;
  }

  execute() {
    // console.log("execute:delete anot", this.annotationId)
    this.deletedAnnotation = this.store.getAnnotWithId(this.annotationId);
    this.store.deleteAnnotation(this.annotationId);
    this.addToCommandStack(); // Save the command to the commandStack
    // Clear the redoStack whenever a new command is executed
    this.store.redoStack = [];
  }
  undo() {
    this.store.createSpecificAnnotation(this.deletedAnnotation);
    this.addToRedoStack(); // Save the command to the redoStack

    return "UNDO " + this.constructor.name + " <" + this.annotationId + ">";
  }
  redo() {
    // this.execute(); // Redo simply means executing the command again
    // console.log("execute:delete anot", this.annotationId)
    this.deletedAnnotation = this.store.getAnnotWithId(this.annotationId);
    this.store.deleteAnnotation(this.annotationId);
    this.addToCommandStack(); // Save the command to the commandStack
    return "REDO " + this.constructor.name + " <" + this.annotationId + ">";
  }
}

// UpdateAnnCommand
class UpdateAnnCommand extends Command {
  constructor(annotationId, newData) {
    super();
    this.store = useAnnotationsStore();

    // this.store = store;
    this.annotationId = annotationId;
    this.oldData = null;
    this.newData = newData;
  }

  async execute() {
    // console.log("updateAnnCommand execute");

    const annotation = await this.store.fetchOneAnn(this.annotationId);
    // const annotation = this.store.getAnnotWithId(this.annotationId);
    this.oldData = { ...annotation };
    // console.log("old data:", this.oldData);

    //   this.updateAnnotation(this.annotationId, this.newData);
    this.store.updateAnnotWithData(this.newData);
    this.addToCommandStack(); // Save the command to the commandStack

    // Clear the redoStack whenever a new command is executed
    this.store.redoStack = [];
  }
  undo() {
    //   this.updateAnnotation(this.annotationId, this.oldData);
    this.store.updateAnnotWithData(this.oldData);
    this.addToRedoStack(); // Save the command to the redoStack

    return "UNDO " + this.constructor.name + " <" + this.annotationId + ">";
  }
  async redo() {
    // TODO: check if it works without these lines, also in other classes:
    // // this.execute(); // Redo simply means executing the command again
    // const annotation = await this.store.fetchOneAnn(this.annotationId);
    // // const annotation = this.store.getAnnotWithId(this.annotationId);
    // this.oldData = { ...annotation };

    //   this.updateAnnotation(this.annotationId, this.newData);
    this.store.updateAnnotWithData(this.newData);
    this.addToCommandStack(); // Save the command to the commandStack

    return "REDO " + this.constructor.name + " <" + this.annotationId + ">";
  }
}



// AddLaneCommand
class AddLaneCommand extends Command {
  constructor(newLane) {
    super();
    // this.store = store;
    this.store = useAnnotationsStore();

    this.newLane = newLane;
    this.addedLaneId = null;
  }

  execute() {
    this.addedLaneId = this.store.addSpecificLane(this.newLane);
    this.addToCommandStack(); // Save the command to the commandStack
    // Clear the redoStack whenever a new command is executed
    this.store.redoStack = [];
  }
  undo() {
    this.store.deleteLane(this.addedLaneId);
    this.addToRedoStack(); // Save the command to the redoStack

    return "UNDO " + this.constructor.name + " <" + this.addedLaneId + ">";
  }
  redo() {
    // this.execute(); // Redo simply means executing the command again
    this.addedLaneId = this.store.addSpecificLane(this.newLane);
    this.addToCommandStack(); // Save the command to the commandStack
    return "REDO " + this.constructor.name + " <" + this.addedLaneId + ">";
  }
}



// AddLaneOfTypeCommand
class AddLaneOfTypeCommand extends Command {
  constructor(title, lane_type = "Simpel") {
    super();
    // this.store = store;
    this.store = useAnnotationsStore();

    this.title = title;
    this.lane_type = lane_type;
    this.addedLaneId = null;
  }

  execute() {
    this.addedLaneId = this.store.addLaneOfType(this.title, this.lane_type);
    this.addToCommandStack(); // Save the command to the commandStack

    // Clear the redoStack whenever a new command is executed
    this.store.redoStack = [];

    return this.addedLaneId;
  }
  undo() {
    this.store.deleteLane(this.addedLaneId);
    this.addToRedoStack(); // Save the command to the redoStack

    return "UNDO " + this.constructor.name + " <" + this.addedLaneId + ">";
  }
  redo() {
    // this.execute(); // Redo simply means executing the command again

    this.addedLaneId = this.store.addLaneOfType(this.title, this.lane_type);
    this.addToCommandStack(); // Save the command to the commandStack

    // return this.addedLaneId;
    return "REDO " + this.constructor.name + " <" + this.addedLaneId + ">";
  }
}
// DeleteLaneCommand
class DeleteLaneCommand extends Command {
  constructor(laneId) {
    super();
    // this.store = store;
    this.store = useAnnotationsStore();

    this.laneId = laneId;
    this.deletedLane = null;
    this.deletedAnnotations = null;
  }

  execute() {
    this.deletedLane = this.store.getLaneWithId(this.laneId);
    this.deletedAnnotations = this.store.getAnnotsOnLane(this.laneId);
    this.store.deleteLane(this.laneId);
    this.store.deleteAnnsOnLane(this.laneId);
    this.addToCommandStack(); // Save the command to the commandStack
    // Clear the redoStack whenever a new command is executed
    this.store.redoStack = [];
  }
  undo() {
    //   this.addLane(this.deletedLane);
    this.store.addSpecificLane(this.deletedLane);
    // create deleted annots back:
    this.deletedAnnotations.forEach(annot => {
      this.store.createSpecificAnnotation(annot);
    });
    this.addToRedoStack(); // Save the command to the redoStack
    // fetch annot
    this.store.fetchAnnotations(this.store.project_id)

    return "UNDO " + this.constructor.name + " <" + this.laneId + ">";
  }
  redo() {
    // this.deletedLane = this.store.getLaneWithId(this.laneId);
    this.store.deleteLane(this.laneId);
    this.store.deleteAnnsOnLane(this.laneId);

    this.addToCommandStack(); // Save the command to the commandStack
    // this.execute(); // Redo simply means executing the command again
    return "REDO " + this.constructor.name + " <" + this.laneId + ">";
  }
}

// UpdateLaneCommand
class UpdateLaneCommand extends Command {
  constructor(laneId, newData) {
    super();
    // this.store = store;
    this.store = useAnnotationsStore();

    this.laneId = laneId;
    this.oldData = null;
    this.newData = newData;
  }

  async execute() {
    // const lane = this.store.getLaneWithId(this.laneId);
    const lane = await this.store.fetchOneLane(this.laneId);
    this.oldData = { ...lane };
    //this.store.updateOneLane(this.laneId, this.newData);
    this.store.updateOneLane(this.newData);
    this.addToCommandStack(); // Save the command to the commandStack
    // Clear the redoStack whenever a new command is executed
    this.store.redoStack = [];

  }
  undo() {
    // console.log("--- old data:", this.oldData, "newdata:", this.newData);

    //this.updateLane(this.laneId, this.oldData);
    this.store.updateOneLane(this.oldData);
    this.addToRedoStack(); // Save the command to the redoStack

    return "UNDO " + this.constructor.name + " <" + this.laneId + ">";
  }
  async redo() {
    // this.execute(); // Redo simply means executing the command again

    // const lane = await this.store.fetchOneLane(this.laneId);
    // this.oldData = { ...lane };
    //this.store.updateOneLane(this.laneId, this.newData);
    this.store.updateOneLane(this.newData);
    this.addToCommandStack(); // Save the command to the commandStack


    return "REDO " + this.constructor.name + " <" + this.laneId + ">";
  }
}

// CopyLaneCommand
class CopyLaneCommand extends Command {
  constructor(laneToCopyId) {
    super();
    // this.store = store;
    this.store = useAnnotationsStore();

    this.laneToCopyId = laneToCopyId;
    this.addedLaneId = null;
  }

  async execute() {
    // console.log("execute copy lane", "id:", this.laneToCopyId)
    this.addedLaneId = await this.store.copyLane(this.laneToCopyId);
    this.addToCommandStack(); // Save the command to the commandStack
    // Clear the redoStack whenever a new command is executed
    this.store.redoStack = [];
  }
  undo() {
    this.store.deleteLane(this.addedLaneId);
    this.addToRedoStack(); // Save the command to the redoStack

    return "UNDO " + this.constructor.name + " <" + this.addedLaneId + ">";
  }
  async redo() {
    // this.execute(); // Redo simply means executing the command again
    this.addedLaneId = await this.store.copyLane(this.laneToCopyId);
    this.addToCommandStack(); // Save the command to the commandStack
    return "REDO " + this.constructor.name + " <" + this.addedLaneId + ">";
  }
}

class GenerateFixedAnnsCommand extends Command {
  constructor(fixedAnnSize, laneId) {
    super();
    this.store = useAnnotationsStore();
    this.fixedAnnSize = fixedAnnSize;
    this.laneId = laneId;
  }
  async execute() {
    // const lane = await this.store.fetchOneLane(this.laneId);
    const annsOnLane = await this.store.fetchAnnsOnLane(this.laneId);

    // this.oldLane = { ...lane };
    this.oldAnnsOnLane = { ...annsOnLane };

    this.store.generateFixedAnnotsOnLane(this.fixedAnnSize, this.laneId);

    this.addToCommandStack(); // Save the command to the commandStack
    // Clear the redoStack whenever a new command is executed
    this.store.redoStack = [];
  }
  undo() {
    // console.log("-----:::: this.oldAnnsOnLane", this.oldAnnsOnLane);

    this.store.updateAnnsOnLane(this.laneId, this.oldAnnsOnLane);
    this.addToRedoStack(); // Save the command to the redoStack

    return "UNDO " + this.constructor.name + " <" + this.laneId + ">";
  }
  async redo() {
    // const lane = await this.store.fetchOneLane(this.laneId);
    // const annsOnLane = await this.store.fetchAnnsOnLane(this.laneId);
    // // this.oldLane = { ...lane };
    // this.oldAnnsOnLane = { ...annsOnLane };

    this.store.generateFixedAnnotsOnLane(this.fixedAnnSize, this.laneId);

    this.addToCommandStack(); // Save the command to the commandStack
    return "REDO " + this.constructor.name + " <" + this.laneId + ">";
  }
}

// Export command classes
export {
  AddAnnotationCommand, AddSpecificAnnotationCommand, DeleteAnnotationCommand, UpdateAnnCommand,
  AddLaneCommand, AddLaneOfTypeCommand, DeleteLaneCommand, UpdateLaneCommand,
  CopyLaneCommand, GenerateFixedAnnsCommand
};
