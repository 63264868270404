import { createRouter, createWebHashHistory, createWebHistory } from "vue-router";
import auth from '../middleware/auth';


const routes = [
  {
    path: "/signin-redirect",
    name: "SigninRedirect",
    component: () => import("../views/SigninRedirect.vue"),
  },
  {
    path: "/",
    name: "MainVideos",
    component: () => import("../views/MainVideos.vue"),
    meta: {
      middleware: auth
    }
  },
  {
    path: "/create_project",
    name: "CreateProject",
    component: () => import("../views/CreateProject.vue"),
    meta: {
      middleware: auth
    }
  },
  // {
  //   path: "/temp",
  //   name: "temp",
  //   component: () => import("../views/temp.vue"),
  //   meta: {
  //     middleware: auth
  //   }
  // }, 
  {
    path: "/video_admin",
    name: "VideoAdmin",
    component: () => import("../views/VideoAdmin.vue"),
    meta: {
      middleware: auth
    }
  },
  {
    path: "/hilde",
    name: "Hilde",
    component: () => import("../views/Hilde.vue"),
    meta: {
      middleware: auth
    }
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/About.vue"),
    meta: {
      middleware: auth
    }
  },
  {
    path: '/documentation/:lang',
    name: 'Documentation',
    component: () => import('../views/Documentation.vue'),
    meta: {
      middleware: auth
    }
  },
  {
    path: "/annotations/:project_id",
    name: "AnnotationApp",
    component: () => import("../views/AnnotationApp.vue"),
    meta: {
      middleware: auth
    }
  },
  {
    // 404 not found paths: 
    path: '/:pathMatch(.*)*',
    component: () => import("../views/PathNotFound.vue"),
  },

];

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),

  routes,
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
