<template>
  <nav
    class="
      navbar navbar-expand-lg navbar-light
      bg-light
      border border-bottom
      p-0
    "
  >
    <div class="container-fluid">
      <span class="navbar-brand fw-bold text-secondary">
        <router-link class="nav-link" to="/"
          ><img src="../assets/cu2rve.png" width="150" alt="Cu2RVE Logo" /> -
          Annotation Tool</router-link
        >
      </span>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link" to="/about">About</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/documentation/de"
              >Bedienung</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/create_project"
              >Projekt erstellen</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/video_admin"
              >Video Administration</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/hilde">Hilde</router-link>
          </li>
        </ul>
        <div class="d-flex">
          <p class="me-2 align-middle py-auto mt-2">
            Hallo <b>{{ this.annotationsStore.getLoggedInUser()?.email }}!</b>
          </p>
          <button
            type="button"
            class="btn btn-outline-primary me-3 align-self-center"
            @click="logout"
          >
            Logout
          </button>
        </div>
        <div class="d-flex">
          <img
            class="p-1"
            src="../assets/uni_logo.svg"
            alt="Logo der Uni Hildesheim"
            width="70"
          />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import auth_service from "../user_manager_auth";
import { mapStores } from "pinia";
import { useAnnotationsStore } from "@/store/useAnnotations";

export default {
  name: "Navbar",
  methods: {
    logout() {
      console.log("startin logout");
      auth_service.startSignoutMainWindow();
    },
  },
  computed: {
    ...mapStores(useAnnotationsStore),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
