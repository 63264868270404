import auth_service from '../user_manager_auth'

export default async function auth({ from, to, next, router }) {
    const redirect_uri = to.fullPath;

    var user = await auth_service.getUser();
    console.log("--user:", user);


    if ((!user) || user == null || user == undefined) {
        auth_service.startSigninMainWindow(redirect_uri)
        next(false)
    } else if (user && user.expired) {
        auth_service.startSigninMainWindow(redirect_uri)
        next(false)
    } else {
        sessionStorage.setItem("auth-token", user.access_token)
        next()

    }
}