import { Log, UserManager, WebStorageStateStore } from "oidc-client-ts";

Log.setLogger(console);
Log.setLevel(Log.INFO);

export const oidcSettings = {
  response_mode: "query",
  authority: process.env.VUE_APP_FRONTEND_OIDC_AUTH_HOSTNAME,
  client_id: process.env.VUE_APP_OIDC_AUTH_CLIENT_ID,
  response_type: 'code',
  scope: process.env.VUE_APP_OIDC_AUTH_SCOPE,
  redirect_uri: `${process.env.VUE_APP_FRONTEND_PATH}/signin-redirect`,
  post_logout_redirect_uri: `${process.env.VUE_APP_FRONTEND_PATH}/`,
  loadUserInfo: true,
  userStore: new WebStorageStateStore({ localStorage: window.localStorage }),
  // automaticSilentRenew: true,
  automaticSilentRenew: true,
  //silentRequestTimeout: 10000,
  filterProtocolClaims: true
};

export {
  Log,
  UserManager
};
