import { oidcSettings } from "./config/oidc.js"
import { UserManager } from "oidc-client-ts"
import { useUserStore } from "@/store/useUser";
const mgr = new UserManager(oidcSettings);


///////////////////////////////
// events
///////////////////////////////
mgr.events.addAccessTokenExpiring(function () {
    // console.log("token expiring");
    // log("token expiring");
});

mgr.events.addAccessTokenExpired(function () {
    console.log("token expired");
    // log("token expired");
});

mgr.events.addSilentRenewError(function (e) {
    console.log("silent renew error", e.message);
    // log("silent renew error", e.message);
});

mgr.events.addUserLoaded(function (user) {
    // console.log("user loaded", user);
    // console.log("updating userStore");
    const userStore = useUserStore();
    userStore.AUTH_token = user.access_token
    userStore.user_profile = user.profile
});

mgr.events.addUserUnloaded(function (e) {
    console.log("user unloaded");
});




var auth_service = {
    clearState() {
        mgr.clearStaleState().then(function () {
            console.log("clearStateState success");
        }).catch(function (err) {
            console.error(err);
            // log(err);
        });
    },
    async getUser() {
        let current_user = undefined;
        await mgr.getUser().then(function (user) {
            current_user = user;
            sessionStorage.setItem("auth-token", user.access_token)
            // sessionStorage.setItem("auth-token", user.id_token)
            //// userStore.AUTH_token = user.access_token
            console.log("updating userStore");
            const userStore = useUserStore();
            userStore.AUTH_token = user.access_token
            userStore.user_profile = user.profile
        }).catch(function (err) {
            console.log("can't get user... trying silent signin")
            console.error(err);
        });

        return current_user;
    },
    removeUser() {
        mgr.removeUser().then(function () {
            console.log("user removed");
        }).catch(function (err) {
            console.error(err);
        });
    },
    startSigninMainWindow() {
        console.log("start signin main window")
        mgr.signinRedirect().then(function () {
            console.log("signinRedirect done");
        }).catch(function (err) {
            console.error(err);
        });
    },
    endSigninMainWindow() {

        if (window.location.hash) {
            window.location.hash = decodeURIComponent(window.location.hash);
        }

        mgr.signinRedirectCallback().then(user => {
            if (window.location.origin == process.env.VUE_APP_FRONTEND_PATH) {
                window.history.replaceState({}, window.document.title, window.location.origin + window.location.pathname);
            }
            window.location = user.state || "/";

        }).catch(error => {
            console.error("--err", error);
            // log(err);
        });
    },
    popupSignin() {
        mgr.signinPopup().then(function (user) {
            console.log("signed in", user);
        }).catch(function (err) {
            console.error(err);
            // log(err);
        });
    },
    popupSignout() {
        mgr.signoutPopup().then(function () {
            console.log("signed out");
        }).catch(function (err) {
            console.error(err);
            // log(err);
        });
    },
    iframeSignin() {
        console.log("signinsilent:")
        mgr.signinSilent().then(function (user) {
            console.log("signed in", user);
        }).catch(function (err) {
            console.error(err);
            // log(err);
        });
    },
    startSignoutMainWindow() {
        mgr.signoutRedirect().then(function (resp) {
            console.log("signed out", resp);
        }).catch(function (err) {
            console.error(err);
            // log(err);
        });
    },
    endSignoutMainWindow() {
        mgr.signoutRedirectCallback().then(function (resp) {
            console.log("signed out", resp);
        }).catch(function (err) {
            console.error(err);
            // log(err);
        });
    },
    signInReal(args) {
        return mgr.signinRedirect(args)
    }

};

export default auth_service;
