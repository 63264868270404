import { defineStore } from "pinia";
import axios from "axios";
import auth_service from '../user_manager_auth'

export const useUserStore = defineStore("user", {
  /* This store holds all state about the user. In order to use the store in a component,
   simply put ...mapStore(useAnnotationStore) in the "computed" part of a Vue component and "import {useAnnotationsStore} from '@/store/useAnnotations'" to load the store.
   The store is then available in the component by the name "annotationsStore" (it is automatically set by pinia to be the StoreID+"Store").
   
   The "state" variable holds all variables of the store. The "actions" property holds all methods like setters.
   */
  state: () => ({
    HILDE_username: null,
    HILDE_token: null,
    AUTH_token: null,
    user_profile: null
    // below: hard coded for oidc bypass
    // AUTH_token: "test",
    // user_profile: {
    //   email: "test@test.com"
    // }
  }),
  getters: {
  },
  actions: {
    // ----------- Backend communication ------------------------
    setTokenToSessionStorage: (state, token) => {
      sessionStorage.setItem("hilde-token", token);
    },
    setData(username, token) {
      this.HILDE_username = username
      this.HILDE_token = token

      // fixme save to storage according to https://github.com/Seb-L/pinia-plugin-persist/issues/46
      let newState = { "HILDE_username": this.HILDE_username, "HILDE_token": this.HILDE_token }
      sessionStorage.setItem('hildeUserData', JSON.stringify(newState))

    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'hildeUserData',
        storage: sessionStorage,
      },
    ],
  }
});
